<template>
  <div>
    <div class="box">
      <!-- 顶部导航栏 -->
      <Header></Header>
      <!-- 页面内容 -->
      <main>
        <Sidebar style="width: 208px; height: calc(100vh - 65px)"></Sidebar>
        <router-view
          style="
            width: calc(100% - 208px);
            padding: 0 25px;
            height: calc(100vh - 65px);
            overflow: auto;
          "
        />
      </main>
    </div>
  </div>
</template>
<script>
import Header from "./header";
import Sidebar from "./sidebar";
export default {
  components: {
    Header,
    Sidebar,
  },
};
</script>
<style scoped>
main {
  display: flex;
  
}
</style>
