<template>
  <div class="sidebar">
    <el-menu :default-active="open1" class="el-menu-vertical-demo" router>
      <el-menu-item
        v-for="(item, index) in Permission"
        :key="index"
        :index="item.link"
      >
        <span slot="title">{{ item.name }}</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Permission: [],
      system: [
        {
          link: '/pushDatea',
          name: '推广数据'
        },
        {
          link: '/entryInstitution',
          name: '机构初步录入'
        },
        {
          link: '/pushUser',
          name: '推广用户跟进'
        }
      ]
    }
  },
  mounted() {
    this.Permission = this.system
  },
  computed: {
    open1() {
      return this.$route.path
    }
  },
  methods: {}
}
</script>
<style scoped>
::v-deep .el-menu {
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 40px;
}
::v-deep .el-menu-item.is-active {
  border-right: 2px solid rgba(25, 144, 255, 100);
}
::v-deep .el-menu-item {
  height: 40px;
  line-height: 40px;
}
</style>
