import Vue from 'vue'
import Router from 'vue-router'
import Layout from '../views/layout.vue'

Vue.use(Router)

const Routes = [{
        path: '*',
        redirect: sessionStorage.getItem("Permission")
    },
    {
        path: '/login',
        name: 'login',
        component: () =>
            import('../components/login.vue')
    },
    {
        path: '/',
        redirect: "/pushDatea",
        component: Layout, // 导入组件 Layout
        hidden: true,
        children: [{
                path: '/pushDatea',
                component: () =>
                    import('@/views/PushDatea')
            },
            {
                path: '/entryInstitution',
                component: () =>
                    import('@/views/EntryInstitution')
            },
            {
                path: '/pushUser',
                component: () =>
                    import('@/views/PushUser')
            }
        ]
    }
]

const createRouter = () => new Router({
    routes: Routes
})

const router = createRouter()


export default router;